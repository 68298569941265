import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    titleName: sessionStorage.getItem('titleName') || 'HUGEMOBI',
    regionsValue: sessionStorage.getItem('regionsValue') || 80,
    userValue: sessionStorage.getItem('userValue') || 120,
    partnersValue: sessionStorage.getItem('partnersValue') || 100,
    serviceValue: sessionStorage.getItem('serviceValue') || 160,
    locationValue: sessionStorage.getItem('locationValue') || '',
    mailValue: sessionStorage.getItem('mailValue') || '',
    phoneValue: sessionStorage.getItem('phoneValue') || '',
    recordValue: sessionStorage.getItem('recordValue') || 'Copyright © 2023 HUGEMOBI LIMITED'
  },
  getters: {
  },
  mutations: {
    // 修改网页title名称
    changeTitle(state, value) {
      state.titleName = value
      sessionStorage.setItem('titleName', value)
    },
    changeRegions(state, value) {
      state.regionsValue = value
      sessionStorage.setItem('regionsValue', value)
    },
    changeUser(state, value) {
      state.userValue = value
      sessionStorage.setItem('userValue', value)
    },
    changePartners(state, value) {
      state.partnersValue = value
      sessionStorage.setItem('partnersValue', value)
    },
    changeService(state, value) {
      state.serviceValue = value
      sessionStorage.setItem('serviceValue', value)
    },
    changeLocation(state, value) {
      state.locationValue = value
      sessionStorage.setItem('locationValue', value)
    },
    changEmail(state, value) {
      state.mailValue = value
      sessionStorage.setItem('mailValue', value)
    },
    changePhone(state, value) {
      state.phoneValue = value
      sessionStorage.setItem('phoneValue', value)
    },
    changRecord(state, value) {
      state.recordValue = value
      sessionStorage.setItem('recordValue', value)
    }
  },
  actions: {
  },
  modules: {
  }
})
