<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    // document.title = sessionStorage.getItem('labelName') || 'hugemobi'
    document.title = 'GREATMOBI'
  },
  methods: {}
}
</script>

<style lang="scss">
html,
body {
  min-height: 100%;
  width: 100%;
}
</style>
